<template lang="html">
  <div id="about-us-page">

    <section class="about-us-section">
      <div class="img-bg"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-image">
            <!-- <img src="public/images/aizea.svg"> -->
          </div>

          <div class="mt-3 mt-lg-0 col-lg-6 col-info">
            <div class="mb-3 box-g-title1 purple">
              <h5 class="title">Why Aizea</h5>
            </div>

            <p>
              Working for more than 30 years in the ”startup” sector with in-depth experience in business development,
              Communications, Public relations and Capital Markets Advisory, Aïzéa’s Founder (Mr Sylvain Archambault)
              has developed a keen knowledge and understanding of the key ingredients required to attain success in the
              corporate world.
            </p>
            <p class="mt-3">
              Key Ideas, adequate and ongoing funding, execution of business model (with constant readjustments),
              selection of the proper partners and needed expertise combined with a little bit of luck are ALL critical and
              equally important ingredients required during any projects Journey’s to success.
            </p>

            <p class="mt-3">
              Because of these multiple complexes tasks, an entrepreneur must be properly steered, supported and
              understood by a trusted partner. The respect of the project’s vision, its accomplishment and ultimate success,
              carried through absolute integrity and respect of everyone’s role and participation must be its only guiding
              principals.<br />
              This Is why Aïzéa Group was created.
            </p>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
