import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '*', component: require(page+'error.vue').default, meta:{title:"Error"}},

	    { path: '/', component: require(page+'home/index-v2.vue').default },

      { path: '/who-we-are', component: require(page+'about-us/who-we-are.vue').default, meta:{title:"Who we are"}},
      { path: '/why-aizea', component: require(page+'about-us/why-aizea.vue').default, meta:{title:"Why Aizea"}},
      { path: '/mission', component: require(page+'about-us/mission.vue').default, meta:{title:"Mission"}},
      { path: '/global', component: require(page+'about-us/global.vue').default, meta:{title:"Global"}},

      { path: '/advent', component: require(page+'division/advent.vue').default, meta:{title:"Advent"}},
      { path: '/koalitio', component: require(page+'division/koalitio.vue').default, meta:{title:"Koalitio"}},
      { path: '/beaprospect', component: require(page+'division/beaprospect.vue').default, meta:{title:"Be a prospect"}},

      { path: '/projects', component: require(page+'project/index.vue').default, meta:{title:"Project"}},
      { path: '/contact', component: require(page+'contact/index.vue').default, meta:{title:"Contact"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "AIZEA", filter: (title)=>{ return title+" - AIZEA"; } }
);

// export {routes};
export default MyRouter;
