<template lang="html">
  <div id="koalitio-page">

    <section class="info-section">
      <div class="img-bg"></div>

      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-info"></div>

          <div class="col-lg-6 col-info">
            <p class="text-center">
              <img class="img-logo" src="public/images/pages/division/koalitio.png">
            </p>

            <h5 class="subtitle">Advanced Computing</h5>
            <p>
              Advanced ComputingHigh Performance ComputingQuantum ComputingData Analytics & BusinessComputingAl & Edge ComputingInfrastructureAl Software.
            </p>

            <br />
            <h5 class="subtitle">Analytics, Al & Automation</h5>
            <p>
              Automation and Robotic Process Automation (RPA)Cognitive SolutionsData & Analytics.
            </p>

            <br />
            <h5 class="subtitle">Cloud Solutions</h5>
            <p>
              Cloud Advisory & AdoptionCloud Application TransformationCloud Data Analytics & Al/MLDigital Cloud PlatformsDigital Platforms for SAPDatacenter Cloud Transformation.
            </p>

            <br />
            <h5 class="subtitle">Customer Experience</h5>
            <p>
              CX Transformation DesignCX ImmersiveCX DesignCX DevelopmentCX PlatformsCX Intelligence.
            </p>

            <br />
            <h5 class="subtitle">Cybersecurity</h5>
            <p>
              Advanced Detection and ResponseData Protection and GovernanceDigital Workplace SecurityHybrid Cloud Security loT and OT SecurityTrusted Digital IdentitiesCybersecurity ProductsView Cybersecurity solutions.
            </p>

            <br />
            <h5 class="subtitle">Digital Consulting</h5>
            <p>
              (We assist CIOs in the acomplishment of their vital systems needs & vision).<br />
              Digital Transformation ConsultingInternet of Things (IoT)Delivering smart business services that harness the value of your loT data.Infrastructure & Foundation ServicesDesigning, building and managing digital infrastructures.
            </p>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
