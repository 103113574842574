<template lang="html">
  <div id="home-page-v2">

    <section class="banner-section-v2">
      <img class="img-desktop" src="public/images/pages/home/banner-01.jpg">
      <img class="img-mobile" src="public/images/pages/home/banner-01-m.jpg">
    </section>

    <section class="container oversized-container about-section-hv2">
      <div class="row flex-center-xy">
        <div class="col-lg-5 col-xl-6 col-image">
          <div class="row flex-center-xy">
            <div class="col-6">
              <router-link target="_blank" to="/koalitio">
                <img src="public/images/pages/division/koalitio.png" alt="Koalitio">
              </router-link>
            </div>
            <div class="col-6">
              <router-link target="_blank" to="/advent">
                <img src="public/images/pages/division/advent.jpg" alt="Advent">
              </router-link>
            </div>
            <div class="col-7 col-sm-6">
              <router-link  target="_blank"to="/beaprospect">
                <img src="public/images/pages/division/beaprospect.png" alt="Beaprospect">
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-3 mt-lg-0 col-info">
          <div class="box-g-title1 purple">
            <h5 class="title">Aizea World Enterprises</h5>
          </div>


          <p>
            Aïzéa World Enterprises reunites a consortium of companies providing a complete ecosystem
            of skillsets and resources to assist and support ideas, individuals and initiatives that enhance
            peoples lives and further society.
          </p>
          <p class="mt-3">
            We seek promising, innovative, unconventional and disruptive projects.
          </p>

          <p class="mt-3">
            The complementary skills and services offered by the our consortium and vast network will
            serve as pillars for the support, growth and development of these Ventures.
            Our guiding principles are to assist individuals and companies in attaining success, without the
            fear of being tainted by undue limitations or  corrupted values.
          </p>
        </div>
      </div>
    </section>

    <section class="container oversized-container map-section">
      <div class="row justify-content-center">
        <div class="col-12 col-top box-g-title1 purple">
          <h2 class="title">Our Global Presence</h2>
          <h5>Working with you around the globe and the clock</h5>
        </div>

        <div class="col-lg-10 col-map">
          <img src="public/images/pages/home/map.png">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [
        { device: 'pc', imageUrl: 'public/images/pages/home/banner-1.jpg' },
        { device: 'movil', imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
      ],

      /* == Carousel options == */
      bannersOption: {
        loop: false,
        speed:800,
        effect: 'fade',

        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },
    }
  },

  methods: {

  }
}
</script>
