<template lang="html">
  <div id="advent-page">

    <section class="container oversized-container info-section">
      <div class="row flex-center-xy">
        <div class="col-lg-5 col-xl-4 col-image">
          <a target="_blank" href="https://adventcorporate.management/">
            <img src="public/images/pages/division/advent.jpg">
          </a>
        </div>

        <div class="col-lg-6 col-info">
          <div class="mb-2 box-g-title1 purple">
            <h5 class="title">About Advent</h5>
          </div>

          <p>
            Founded in 2003, Advent Management is a Strategic Consulting Firm that assists and guides Entrepreneur from the private & public domains with the elaboration of business plan, sales and business development strategies, financial communication strategies and campaign while identifying and coordinating the actors involved in the proper execution of the chosen strategies.
          </p>
          <p class="mt-2">
            Advisory services for Capital Markets strategies, M&A and business development.
          </p>
        </div>
      </div>
    </section>

    <section class="container-fluid line-section">
      <hr />
    </section>

    <!-- <section class="container-fluid fixed-section">
      <div class="box"></div>
    </section> -->

    <section class="extra-section">
      <div class="img-bg"></div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-info">
            <h5 class="subtitle">Services & Role</h5>

            <h6>Project analysis (Potential and requirements)</h6>
            <ul class="no-list">
              <li>Funding</li>
              <li>Expertise required</li>
              <li>Human Resources</li>
            </ul>

            <h6>Strategic Planning</h6>
            <ul class="no-list">
              <li>Material preparation</li>
              <li>Capital Market strategy</li>
            </ul>

            <h6>Monitoring</h6>
            <h6>Representation & Communication</h6>

            <h5 class="mt-3 subtitle">Highlites</h5>

            <ul class="no-list">
              <li>18 Years Operations (Vast NetworkCredibility-Expertise)</li>
              <li>International Links with Strategic Partners – Investment Industry– Government – Sports Associations)</li>
              <li>Instrumental in strategic investments of more than $500M for Private and Public companies</li>
              <li>Behind the launch of more than 40 Successful IPO’s and Private ventures.</li>
            </ul>
          </div>

          <div class="col-lg-6 col-info">

          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
