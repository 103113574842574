<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5 text-center col-info">
          <h1 class="mb-2 page-title">Contact</h1>

          <p class="mt-2">
            If you have questions and would like to contact us, send us a message through the form.
          </p>

          <br />
          <h5 class="subtitle-sm">Phone:</h5>
          <p>
            <strong class="f-w-600"></strong> 1-877-906-2425 <br />
          </p>

          <br />
          <h5 class="subtitle-sm">Email:</h5>
          <p>
            <strong class="f-w-600">info@aizea.world</strong>
          </p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit="onSubmit">
            <b-form-group label="Name:">
              <b-form-input
                v-model="form.name"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Email:">
              <b-form-input
                v-model="form.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Phone:">
              <b-form-input
                v-model="form.phone"
                type="text"
                minlength="10"
                maxlength="10"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Message:">
              <b-form-textarea
                v-model="form.msg"
                required
                rows="4"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send">Send</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.539927663555!2d-103.39317058469474!3d20.64760150605586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428adc5aa6ac6ad%3A0x96f4c5af1a070bf5!2sC.%20Diamante%202586%2C%20Bosques%20de%20La%20Victoria%2C%2044540%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1629919204691!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null
      },

      info: [],
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Message sent');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  },
}
</script>
