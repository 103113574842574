<template lang="html">
  <div id="error-page">
    <div class="container-fluid">
      <div class="row flex-center-xy">
        <div class="col-12 col-content">
          <h1>¡Error!</h1>
          <h4>Page not found</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
