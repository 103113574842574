<template lang="html">
  <div id="xistenz-page">

    <section class="info-section">
      <div class="img-bg"></div>

      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-info">
            <p class="text-center">
              <img class="img-logo mb-3" src="public/images/pages/division/beaprospect.png">
            </p>

            <div class="box-g-title1 purple">
              <h5 class="title">About Beaprospect Studio</h5>
            </div>

            <p>
              Beaprospect Startup Studio was guided by the vision and purpose of conceiving proprietary IP, champion or support initiatives and projects in order to better society.
            </p>
            <p class="mt-3">
              To marry technology, innovation, ease of use with common sense and social conscience guides us in designing, selecting and promoting purposeful projects.
            </p>
            <p class="mt-3">
              The development of successful and profitable ventures while promoting human respect and positive human values is inscribed in our company's DNA and will always remain our focus.
            </p>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
