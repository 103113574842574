<template lang="html">
  <div id="global-page">

    <section class="container oversized-container map-section">
      <div class="row justify-content-center">
        <div class="col-12 col-top box-g-title1 purple">
          <h2 class="title">Our Global Presence</h2>
          <h5>Working with you around the globe and the clock</h5>
        </div>

        <div class="col-lg-10 col-map">
          <img src="public/images/pages/home/map.png">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
