<template lang="html">
  <header id="header">
    <div class="header-content" v-bind:class="{ 'home-page' : $route.name == 'homePage' }">
      <div class="container oversized-container">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <b-navbar-brand to="/">
            <img src="public/images/aizea-white.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/">Home</b-nav-item>
              <b-nav-item-dropdown right>
                <template #button-content>
                  <span>About Us</span>
                </template>
                <b-dropdown-item to="/who-we-are">Who we are</b-dropdown-item>
                <b-dropdown-item to="/why-aizea">Why Aizea</b-dropdown-item>
                <b-dropdown-item to="/mission">Mission</b-dropdown-item>
                <b-dropdown-item to="/global">Global</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown right>
                <template #button-content>
                  <span>Divisions</span>
                </template>
                <b-dropdown-item to="/advent">Advent</b-dropdown-item>
                <b-dropdown-item to="/koalitio">Koalitio</b-dropdown-item>
                <b-dropdown-item to="/beaprospect">Beaprospect Studio</b-dropdown-item>
              </b-nav-item-dropdown>

              <!-- <b-nav-item to="/projects">Projects</b-nav-item> -->

              <li class="nav-item nav-contact d-none d-lg-inline-block">
                <router-link class="nav-link" to="/contact"><i class="fas fa-envelope-open-text"></i></router-link>
              </li>
              <b-nav-item class="d-lg-none" to="/contact">Contact</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </header>

</template>

<script>
export default {
}
</script>
