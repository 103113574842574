<template lang="html">
  <footer id="footer">
    <section class="footer-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <!-- <div class="col-6 col-xl col-footer">
            <h6>Address</h6>
            <p>
              Av. Diamante #2586<br />
              Col. Bosques de La Victoria<br />
              CP. 44540, Guadalajara, Jalisco.
            </p>
          </div> -->

          <div class="col-xl col-logo">
            <router-link to="/">
              <img src="public/images/aizea-whitefull.svg">
            </router-link>
          </div>

          <div class="col-6 col-md col-footer">
            <h6>Contact</h6>
            <p>
              <i class="fas fa-phone-alt mr-1 icon-1"></i> 1-877-906-2425
            </p>
            <!-- <p class="mt-1">
              <i class="fab fa-whatsapp mr-1 icon-1"></i> 33 0000 0000
            </p> -->
          </div>

          <div class="col-6 col-md col-footer">
            <h6>Emails</h6>

            <p>
              info@aizea.world
            </p>
          </div>

          <!-- <div class="col-12 col-md col-footer">
            <h6>Help</h6>

            <p>
              <router-link to="">Notice of Privacy</router-link>
            </p>
            <p>
              <router-link to="">Terms and Conditions</router-link>
            </p>
          </div> -->

          <!-- <div class="col-12 col-networks">
            <div class="d-block">
              <a class="trans-250 link" href=""><i class="fab fa-facebook-square"></i></a>
              <a class="trans-250 link" href=""><i class="fab fa-instagram"></i></a>
              <a class="trans-250 link" href=""><i class="fab fa-twitter-square"></i></a>
            </div>
          </div> -->
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },
    }
  },

  methods:{
  },

  beforeMount(){
  }
}
</script>
