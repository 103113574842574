<template lang="html">
  <div id="mission-page">

    <section class="info-section">
      <div class="bg-page"></div>

      <div class="container oversized-container">
        <div class="row">

          <div class="mb-3 mb-lg-0 col-lg-6 ml-xl-5 col-info">
            <div class="mb-3 box-g-title1 purple">
              <h5 class="title">Mission</h5>
            </div>

            <p class="mb-2">Aizea World Consortium ensure...</p>
            <ul>
              <li>Subsidies & international support programs for development</li>
              <li>Cheaper and Qualified Programmers</li>
              <li>Access to unknown Projects and relationship with isolated incubators</li>
              <li>Direct Link to University for fresh Talent and Ideas</li>
              <li>R&D leverage through University relationship</li>
              <li>Heightened relationship with international Ventures Capital Funds</li>
              <li>Operational & relational Bridge to International markets</li>
              <li>Continuous operational support and Production capabilities over multiple time zones (24 hours production cycle)</li>
              <li>Over 30 Programmers at our disposal (targeted firm for take over)</li>
              <li>Footprint in Mexico, Spain, Canada, USA</li>
              <li>Strong affiliation with Canadian, Mexican and Spanish Government, Universities, incubators and VCs</li>
            </ul>
          </div>

          <div class="col-lg-5 col-xl-4 col-image">
            <!-- <img src="public/images/aizea.svg"> -->
            <!-- <img src="public/images/pages/home/logos-aizen.jpg"> -->
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
