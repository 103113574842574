<template lang="html">
  <div id="project-page">

    <section class="main-section">
      <div class="container">
        <div class="box-g-title1 purple">
          <h5 class="title">Portfolio of Projects & IP</h5>
        </div>

        <div class="row mt-5">
          <div class="col-lg-6 col-info">
            <div class="box-logo">
              <img src="public/images/pages/projects/2be.png">
            </div>

            <h5 class="subtitle">The digital gateway to your life & passion</h5>
            <ul>
              <li>Organiser</li>
              <li>Archive</li>
              <li>Guide</li>
            </ul>
          </div>

          <div class="col-lg-6 col-info">
            <div class="box-logo">
              <img src="public/images/pages/projects/zea.png">
            </div>

            <h5 class="subtitle">Source of Future IP:</h5>
            <ul>
              <li>Partners Referal (Advent-Koalitio-VC’sUniversities- Etc…)</li>
              <li>Internal IP Development</li>
              <li>Funding partners & Investment</li>
            </ul>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
